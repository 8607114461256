.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}

.react-datepicker__input-container input[type='text'] {
  height: 38px;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  padding: 0.78571429em 1em 0.78571429em 1em;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  width: 100%;
}

.react-datepicker__input-container {
  width: 100%;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-popper {
  min-width: max-content;
}

.react-datepicker__handleSevenDaysClick {
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}

#root .date-picker_error > div > div > input {
  border: 1px solid #9f3a38;
  border-radius: 6px;
  background: #9f3a3814;
}
