#root .entries-change-button {
  display: flex;
  justify-content: flex-end;
}

#root .entries-change-button-void:hover {
  outline: 1px solid rgba(136, 50, 140, 0.6);
}

#root .entries-change-button-jackpot:hover,
#root .entries-change-button-bonus:hover {
  outline: 1px solid rgba(234, 107, 40, 0.6);
}
