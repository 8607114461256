#root {
  .question-tab {
    margin: 0 auto;
    width: 800px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &__header {
      color: rgba(0, 0, 0, 0.65);
      margin-top: 130px;
    }

    &_empty {
      align-items: center;
    }

    .question-button-panel {
      display: flex;
      justify-content: center;
      gap: 4rem;
      padding: 0;
      margin: 1rem 0;

      .feed-question-modal {
        width: 75%;
      }
    }

    .question-card {
      width: 100%;

      .question-header-row {
        width: 100%;
        padding: 0.4rem;
        background: rgba(249, 250, 251, 1);
        border-radius: 0.28571429rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__info-description {
          width: 100%;
          &-wrapper {
            padding: 0.4rem;
          }
        }

        &-wrapper {
          display: flex;
          flex-direction: column;
        }

        &__description {
          width: 230px;

          &_long {
            width: 315px;
          }
        }

        &__type {
          width: fit-content;
        }

        &__manual {
          width: fit-content;
          color: #3385cb;
        }

        &__time-section {
          display: flex;
          align-items: center;
          gap: 1rem;

          & input {
            color: inherit;
          }

          &_end {
            color: #f24333;
          }

          &_live {
            color: #04ba00;
          }
        }

        &__time-label {
          margin: 0 12px 0 0;
        }

        &__btn-delete {
          padding: 0 0 2px 0;
          background: transparent;
          color: #676768;

          &:hover {
            color: rgba(0, 0, 0, 0.8);
          }
        }
      }

      .question-answer-row {
        width: 100%;
        padding: 6px 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__inputs-wrapper {
          display: flex;
          gap: 0.8rem;
        }

        &__description {
          width: 290px;
        }

        &__score {
          width: 70px;
        }

        &__change-button {
          &:nth-child(1) {
            color: rgba(47, 124, 33, 1) !important;
          }

          &:nth-child(2) {
            color: rgba(242, 67, 51, 1) !important;
          }

          &:nth-child(3) {
            color: rgba(120, 30, 124, 1) !important;
          }

          &:focus {
            box-shadow: none;
          }

          &:hover {
            box-shadow: 0 0 0 0.5px rgb(34 36 38 / 35%) inset, 0 0 0 0 rgb(34 36 38 / 15%) inset;
          }

          &_active {
            &:nth-child(1) {
              background-color: rgba(47, 124, 33, 0.1) !important;
            }

            &:nth-child(2) {
              background-color: rgba(242, 67, 51, 0.1) !important;
            }

            &:nth-child(3) {
              background-color: rgba(120, 30, 124, 0.1) !important;
            }
          }
        }

        &_range {
          gap: 2rem;

          & > div:first-child {
            width: 26%;
          }

          & > div:last-child {
            width: 13%;
          }
          & > div:nth-child(2) {
            width: 53%;
          }

          & .question-range-input {
            &-wrapper {
              display: flex;
              align-items: center;
              gap: 1rem;

              & > * {
                margin: 0;
                padding: 0;
              }
            }

            &_small {
              width: 57px;
            }

            &_medium {
              width: 20%;
            }

            &_large {
              width: 58%;
            }
          }
        }
      }

      .question-finish-row {
        width: 100%;
        padding: 6px 12px 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__add-outcome-section {
          display: flex;
          align-items: center;
        }

        &__header {
          margin: 0 1.5rem 0;

          &_with-btn {
            margin: 0 1rem 0;
          }
        }

        &__league-input {
          width: 200px;
          margin: 0 0 0 2px;
        }

        &__void-button {
          color: rgba(120, 30, 124, 1) !important;

          &:hover {
            box-shadow: 0 0 0 0.5px rgb(34 36 38 / 35%) inset, 0 0 0 0 rgb(34 36 38 / 15%) inset;
          }

          &_active {
            background-color: rgba(120, 30, 124, 0.1) !important;
          }
        }
      }
    }
  }
}
