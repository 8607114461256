#root .text-editor-wrapper {
  border-radius: 0.28571429rem;
  border: 1px solid rgba(34, 36, 38, 0.15);
}

#root .text-editor-wrapper_error {
  border-color: #e0b4b4;
  color: #9f3a38;
}

#root .text-editor-wrapper_error .text-editor__textarea {
  background: #fff6f6;
}

#root .text-editor__textarea {
  padding: 1em 0.78571429em;
  border-top: 0;
  border-radius: 0 0 0.28571429rem 0.28571429rem;
  min-height: 85px;
}

#root .DraftEditor-root {
  min-height: 55px;
}

#root .public-DraftEditorPlaceholder-root {
  color: #bdc1c9;
}

#root .public-DraftEditorPlaceholder-hasFocus {
  color: #9197a3;
}

#root .text-editor__textarea > div > div > div > div > *:not(:last-child) {
  margin-bottom: 0.5rem;
}

#root .text-editor__textarea blockquote {
  margin: 0 0 0 1rem;
  padding: 0.5rem;
  background: #c1c1c1;
  border-radius: 0.28571429rem;
}

#root .text-editor__toolbar {
  justify-content: space-between;
  border: 0;
}

#root .text-editor__toolbar div {
  width: 100%;
  border-radius: 0.28571429rem 0.28571429rem 0 0;
  border: 0;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
}

#root .text-editor__toolbar button {
  margin-right: 0;
  border-top: 0;
}
