#root {
  .pool-page-statistic {
    font-size: 14px;
    padding: 1rem 1rem 0;
    min-height: 0;

    * {
      margin: 0;
      padding: 0;
    }

    &-wrapper {
      max-height: 282px;
      overflow: hidden;
      overflow-y: auto;
    }

    &-cell {
      padding: 0 1rem 0 0;
      margin: 0 0 1rem;

      & > div {
        font-weight: 700;
        color: rgb(0, 0, 0, 0.65);
        font-size: 0.6rem;
        text-transform: capitalize;
      }

      &-column &:first-child > span {
        font-weight: 800;
        color: rgb(0, 0, 0);
        font-size: 1.4rem;
      }

      &_disable {
        color: rgb(0, 0, 0, 0.55) !important;
      }

      &_bold > span {
        font-weight: 800;
        color: rgb(0, 0, 0);
      }

      &_opacity {
        opacity: 0;
      }
    }
  }

  .pool-page-statistic-column:first-child .pool-page-statistic-cell,
  .pool-page-statistic-column:nth-child(2) .pool-page-statistic-cell:first-child,
  .pool-page-statistic-column:nth-child(3) .pool-page-statistic-cell,
  .pool-page-statistic-column:nth-child(4) .pool-page-statistic-cell:nth-child(1),
  .pool-page-statistic-column:nth-child(4) .pool-page-statistic-cell:nth-child(2),
  .pool-page-statistic-column:nth-child(4) .pool-page-statistic-cell:nth-child(4),
  .pool-page-statistic-column:nth-child(5) .pool-page-statistic-cell:nth-child(2),
  .pool-page-statistic-column:nth-child(5) .pool-page-statistic-cell:nth-child(3) {
    color: rgb(0, 0, 0);
    opacity: 0.3;
  }
}

@media (max-width: 1062px) {
  .pool-page-statistic-cell > .header {
    height: 2.6em;
  }
}
