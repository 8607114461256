#root {
  .no-data-component {
    text-align: center;
    padding-top: 28px;
    padding-bottom: 29px;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2rem;
  }
}
