#root {
  .return-button {
    float: left;

    &_long {
      float: left;
    }
  }

  .creation-grid {
    margin: 51px auto;
    width: 602px;
  }

  .small-input-field > div {
    min-width: 7em;
  }

  .form-row {
    margin-bottom: 10px;
    margin-top: 10px;
    padding: 0;
  }

  & .field-label {
    text-align: start;

    &-wrapper {
      padding: 0;
    }
  }

  & .lang-pane {
    margin: 0 0 1rem 0;
    border: 0;
    padding: 0;
  }

  & .creation-grid .row {
    padding: 1rem 0 0 0;
  }

  & .create-pools-page__header {
    margin-bottom: 3rem;
  }

  & .section__header {
    margin-top: 3px;
  }

  & .section__header-wrapper {
    padding-left: 0;
  }

  & .edit-form__language-tabs > .ui.secondary.pointing.menu {
    width: fit-content;
    margin: 1rem 0 1rem auto;
    border: 0;
    padding: 0 0 0.5rem;
  }

  & .ui.secondary.pointing.menu .item {
    border-bottom: 2px solid rgba(34, 36, 38, 0.15);
  }

  & .ui.secondary.pointing.menu .item:active {
    border-bottom: 2px solid rgba(34, 36, 38, 0.32);
  }

  & .ui.secondary.pointing.menu .active.item {
    border-bottom: 2px solid #1b1c1d;
  }

  & .form__section {
    margin: 0 0 2.5rem;
  }

  & .section-payments-info .ui.grid > .row {
    margin: 0 0 0.5rem 0;
  }

  & .ipools-description_highlight {
    margin: 0 0 1rem 1rem;
    padding: 0.5rem;
    background: #c1c1c1;
    border-radius: 0.28571429rem;
  }
}

#root {
  .prize-place {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 1rem 0 1rem 0;
    gap: 1rem;

    &__label {
      width: 70px;
      display: inline-block;
      margin: 0;
      padding: 0;
    }

    &__percent {
      width: 90px;
    }

    &__cash {
      width: 130px;
    }
  }
}

#root {
  .ipools-checkbox-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .ipools-checkbox-toggle {
      width: 50px;
      margin: 0 0.5rem 0 0;
      padding: 0;
    }

    .ipools-checkbox-header {
      text-align: start;
      margin: 0 0.5rem 0 0;
      padding: 0;
    }
  }
}
