#modal {
  .feed-question {
    &-wrapper {
      width: 100%;
    }

    &__market {
      margin: 0.5rem 0;

      &-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    &__description {
      color: #3385cb;
    }

    &-market {
      &__header {
        font-weight: 800;
      }

      &__outcomes {
        display: inline;
      }

      &__button {
        white-space: nowrap;
      }
    }

    &__button_get-more {
      display: block;
      margin: 0 auto;
    }

    &__info-cell {
      position: relative;
    }

    &-table-body tr:nth-child(2n) {
      background: #f9fafb;
    }

    &-table-body tr:hover {
      background: rgba(236, 236, 236, 1);
    }

    &-table-body td {
      padding: 5px 11px;
    }
  }

  .button-up-down {
    transition: 0.2s all linear;
    position: absolute;
    top: 10px;
    right: 0;
    cursor: pointer;
    width: 1.5rem;
    height: 1.5rem;
  }

  .button-up-down_active {
    transition: 0.1s all linear;
    transform: rotateX(180deg);
    position: absolute;
    top: 10px;
    right: 0;
  }
}
