#root .pool-page-entries-table-wrapper {
  margin: 1rem 0;
  width: 100%;
  overflow-x: auto;
}

#root .pool-page-entries-table-wrapper > table {
  min-width: 1200px;
}

#root .pool-pane__header {
  margin-top: 12rem;
}
