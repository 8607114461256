#root {
  .pool-table {
    &__name-cell {
      max-width: 15vw;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__origins-cell {
      max-width: 16vw;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .ipools {
    &_header {
      margin: 0;
      display: inline-block;
    }

    &-container {
      padding: 15px;
      height: calc(100vh - 40px);
    }

    &-table-container {
      margin-top: 15px;
      margin-bottom: 15px;
      width: 100%;
      height: 83vh;
      overflow-x: auto;
      overflow-y: auto;
    }
  }

  .create-pool-button {
    float: right;
  }

  .ipools-table__btn-copy-pool {
    color: rgba(0, 0, 0, 0.36);

    &:hover {
      cursor: pointer;
      color: rgba(0, 0, 0, 0.46);
    }
    &:active {
      color: rgba(0, 0, 0, 0.56);
    }
  }

  .ipools-table__menu {
    margin: 1rem 0.3rem;
    display: flex;
    justify-content: space-between;

    & > div:first-child {
      display: flex;
      gap: 1rem;
    }
  }
}

@media (min-width: 1500px) {
  .pool-table__origins-cell {
    max-width: 30vw;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
