body {
  padding: 0;
  height: 100%;
}

#root {
  overflow: auto;
  height: 100%;
}

.ui.label.super-tiny-label {
  font-size: 0.6rem;
}

.ui.table td {
  padding: 0 0.78571429em;
}

#root .searching-dropdown input {
  padding-left: 4rem;
}

#root .searching-dropdown:hover {
  background: transparent;
}
