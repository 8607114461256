.pool-page {
  height: auto;
  min-height: calc(100vh - 70px);
  margin: 15px;
}

.pool-page__header {
  margin: 0;
}

.pool-page__id {
  margin: 15px 0 0;
}

.pool-page__id span {
  font-weight: 700;
  color: #000000a6;
}

.common-info-grid {
  height: auto;
  min-height: 180px;
}

#root .common-info-grid > div {
  margin: 0;
}

#root .common-info-grid > div > div:first-child {
  padding: 0 15px 0 0;
}

#root .common-info-grid > div > div:last-child {
  padding: 0 0 0 15px;
}

#root .common-info-grid > div > div > div {
  margin: 0;
}

#root .common-info-grid > div > div > div > div:last-child {
  padding: 0;
  margin: 15px 0 0;
}

#root .common-info-grid > div > div > div > div:last-child > div {
  padding: 0;
}

#root .pool-name {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-right: 15px !important;
  display: inline-block;
  max-width: 25vw;
  word-break: break-word;
}

#root .status-dropdown {
  max-height: 30px;
  background: #800080;
  color: white;
  font-size: 11px;
  display: inline-block;
  margin-top: 0.3rem;
}

.status-dropdown > i {
  padding-left: 5px !important;
}

.dates-top-row {
  padding-bottom: 5px !important;
}

.dates-bottom-row {
  padding-top: 5px !important;
}

.open-time {
  color: #04ba00;
}

.end-time {
  color: #de0000;
}

#root .loader-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#root .pool-info__header-cell {
  display: flex;
  align-items: flex-start;
  padding: 0;
}

#root .pool-info__description {
  word-break: break-all;
  padding-right: 1rem;
}

#root .pool-page__header a {
  margin-right: 1rem;
}

@media (max-width: 1400px) {
  #root .pool-info__button_update {
    padding: 0;
  }
}

#root .pool-info__header {
  margin: 0;
  padding: 0;
}

#root .pool-info__header {
  margin: 0;
  padding: 0;
}

#root .pool-info__tab-menu {
  margin: 1rem 0;
}
