#root .entries-desc-long-answer .entries-desc-question {
  margin-left: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  width: 100%;
}

#root .entries-desc-long-answer .entries-desc-title {
  font-weight: 800;
  margin-right: 0.5rem;
  white-space: nowrap;
}

#root .entries-desc-long-answer .entries-desc-score {
  font-weight: 800;
}

#root .entries-desc-long-answer .entries-desc-double {
  color: rgba(1, 94, 146, 1);
  font-weight: 800;
  margin-left: 0.5rem;
}

#root .entries-desc-short-answer .entries-desc-title {
  margin-right: 0.5rem;
}

#root .entries-desc-short-answer .entries-desc-score {
  font-weight: 800;
  margin-right: 1rem;
}

#root .entries-desc-short-answer .entries-desc-title_active,
#root .entries-desc-short-answer .entries-desc-score_active {
  color: rgba(1, 94, 146, 1);
}

#root .entries-desc-cell-answers {
  float: left;
  width: 100%;
}

#root .entries-desc-cell-answers_short {
  float: left;
  max-height: 3rem;
  overflow: hidden;
}

#root .entries-desc-short-answer {
  display: inline-block;
}

#root .entries-desc-long-answer {
  display: flex;
}

#root .entries-desc-cell-answers-wrapper {
  position: relative;
  min-width: fit-content;
  padding-right: 2rem !important;
}

#root .button-up-down {
  transition: 0.2s all linear;
  position: absolute;
  top: 10px;
  right: 0;
  cursor: pointer;
}

#root .button-up-down_active {
  transition: 0.1s all linear;
  transform: rotateX(180deg);
  position: absolute;
  top: 10px;
  right: 0;
}

#root .entries-desc-cell-jackpot,
#root .entries-desc-cell-bonus {
  color: rgba(234, 107, 40, 1);
}

#root .entries-desc-cell-void {
  color: rgba(136, 50, 140, 1);
}

#root .entries-table-row > td {
  vertical-align: top;
  padding: 8px 12px;
}

#root .entries-table-row:hover {
  background: rgba(236, 236, 236, 1);
}

#root .entries-table-row_active {
  background: rgba(245, 245, 245, 1);
}
